<template>
  <Popup>
    <div class="header-content">
      <img @click="closePopup" style="cursor: pointer; margin-top: -1rem" width="25" height="25"
        src="@/assets/eliminar-50.png" alt="delete-sign--v1" />
      <h1 style="
        text-align: center;
        margin-top: 1rem;
        margin-bottom: 3rem;
        color: white;
        font-weight: 100;
      ">
        {{ $t('message.planta.f1') }}
      </h1>
    </div>

    <div>
      <form class="popup-form" @submit.prevent="verificarAddress()">
        <div class="group">
          <input required v-model="newService.direction" class="custom-input" type="text"
            :placeholder="$t('message.planta.f2')" style="padding-left: 0.5rem" />
        </div>
        <div class="btn-content">
          <button class="btn-submit-address" type="submit">
            {{
              coordenate.length > 0
                ? $t('message.planta.f3')
                : $t('message.planta.f4')
            }}
          </button>
        </div>
      </form>
    </div>

    <div style="margin: 20px 0px" v-if="validAddress">
      <Map :height="200" :width="320"></Map>
    </div>

    <form v-if="validAddress" class="popup-form" @submit.prevent="newServices()">
      <div class="group">
        <input v-model="coordenate.requestBody.nickname" class="custom-input" type="text" required
          :placeholder="$t('message.planta.f5')" style="padding-left: 0.5rem" />
      </div>

      <div class="group">
        <v-select v-model="coordenate.requestBody.serviceplanId" :options="changeplan" label="name" required
          :placeholder="$t('message.planta.f6')" class="custom-select" />
      </div>
      <div v-if="newData" class="group">
        <input disabled v-model="coordenate.requestBody.regionCode" class="custom-input" type="text"
          :placeholder="$t('message.planta.c11')" style="padding-left: 0.5rem" />
      </div>
      <div v-if="newData" class="group">
        <input disabled v-model="coordenate.requestBody.latitude" class="custom-input" type="text"
          :placeholder="$t('message.planta.f16')" style="padding-left: 0.5rem" />
      </div>
      <div v-if="newData" class="group">
        <input disabled v-model="coordenate.requestBody.longitude" class="custom-input" type="text"
          :placeholder="$t('message.planta.f15')" style="padding-left: 0.5rem" />
      </div>
      <div class="group">
        <v-select required v-model="coordenate.requestBody.deviceId" :options="serial" label="kitSerialNumber"
          :placeholder="$t('message.planta.f7')" class="custom-select" />
      </div>

      <div class="group">
        <input v-model="coordenate.requestBody.detail" class="custom-input" type="text"
          :placeholder="$t('message.planta.f35')" style="padding-left: 0.5rem" />
      </div>

      <!-- <div class="group">
            <v-select
              v-model="newService.suscriptor"
              :options="suscriptores"
              label="name"
              :placeholder="'Seleccionar Suscriptor'"
              class="custom-select"
            />
          </div> -->

      <div class="btn-content" v-if="!isLoadingNewServices">
        <button :disabled="process" class="btn-submit" type="submit" style="margin-left: 1rem">
          <div style="
              display: flex;
              justify-content: space-evenly;
              width: 90%;
              margin: auto;
              align-items: center;
            ">
            <div>{{ $t('message.planta.f8') }}</div>
            <div>
              <img src="@/assets/Vector-servicio.png" alt="img-vector" />
            </div>
          </div>
        </button>
      </div>

      <div v-else class="spinner-content2">
        <ProgressSpinner style="width: 50px; height: 50px" strokeWidth="8" fill="transparent" animationDuration=".5s"
          aria-label="Custom ProgressSpinner" />
      </div>
    </form>
  </Popup>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Popup from "../../components/planta-conte/Popup.vue";
import store from "../../store";
import "@/assets/sass/StylesPlanta.scss";
import { mapState, mapGetters } from "vuex";
import Map from "../../components/map/index.vue";
import vSelect from "vue-select";

export default defineComponent({
  name: "newService",
  emits: ["close"],
  components: {
    Popup,
    Map,
    vSelect,
  },
  computed: {
    ...mapState({
      coordenate: (state: any) => state.AUTH.coordenateData,
      user: (state: any) => state.AUTH.user,
      principal: (state: any) => state.AUTH.principalSelected,
      account: (state: any) => state.AUTH.accountSelected,
      token: (state: any) => state.AUTH.token
    }),
    ...mapGetters(["permissions"]),

  },
  data() {
    return {
      isLoadingNewServices: false,
      showPopup: false,
      showPopupDrow: false,
      showPopupDrowSus: false,
      showPopupDrowBaja: false,
      showPopupDrowSoporte: false,
      validAddress: false,
      process: false,
      accounts: [],
      newService: {
        nickname: "",
        planservice: "",
        direction: "",
        kitSerialNumber: "",
        suscriptor: "",
        addressLines: "",
        locality: "",
        administrativeArea: "", // mayuscula
        administrativeAreaCode: "", // mayuscula
        region: "",
        regionCode: "", // mayuscula
        postalCode: "",
        latitude: "",
        longitude: "",
        formattedAddress: "",
        productReferenceId: "",
      },
      newData: {
        nickname: null,
        device: null,
        planservice: null,
      },
      changeplan: [],
      suscriptores: [],
      newdirection: null,
      serial: [],
      terminalData: null as null | {
        accountNumber: string;
        platformId: string;
        principalId: string;
        deviceId: number;
        directionId: number;
        serviceplanId: number;
        nickname: string;
        detail: string;
      },
    };
  },
  mounted() {

    // this.customer();
  },
  methods: {
    closePopup() {
      this.$emit("close");
    },
    serviceplan() {
      var data = {
        endpoint: 14,
        type: "data",
        variables: [
          {
            name: "$principalId",
            value: this.principal.principalId
          },
          {
            name: "$onlyserviceplans",
            value: "serviceplans"
          }
        ]
      }
      store
        .dispatch("SERVICELINEUSERCONFIG", data)
        .then((response) => {
          this.changeplan = response.data.data;
          console.log("services", this.changeplan);
          this.changeplan = response.data.data.map(function (
            cadena: any,
          ) {
            return {
              id: cadena.optionsId, name: cadena.name, productReferenceId: cadena.productReferenceId, serviceplanId: cadena.serviceplanId
            };
          });
          this.serialnumber();


        })
        .catch((error) => {
          console.log("error", error);
        });
    },

    customer() {
      var data = {
        endpointId: 4,
        token: this.token,
        principalId: this.user.principalId,
        platform: "starlink",
        roleId: this.user.roleId,
        userId: this.user.id,
        orgId: this.user.orgId,
      }
      store
        .dispatch("SERVICELINEUSERCONFIG", data)
        .then((response) => {
          this.suscriptores = response.data.data;
          this.suscriptores = response.data.data.map(function (
            cadena: any,
          ) {
            return cadena.name;
          });
          this.showPopup = false;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },

    serialnumber() {
      var data = {
        endpoint: 15,
        type: "data",
        variables: [
          {
            name: "$principalId",
            value: this.principal.principalId
          },
          {
            name: "$neednull",
            value: "nulo"
          }
        ]
      }
      store
        .dispatch("SERVICELINEUSERCONFIG", data)
        .then((response) => {
          this.serial = response.data.data;
          this.serial = response.data.data.map(function (
            cadena: any,
          ) {
            return { id: cadena.id, userTerminalId: cadena.userTerminalId, kitSerialNumber: cadena.kitSerialNumber };
          });
          this.showPopup = false;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },

    serviceline(data: any) {
      store
        .dispatch("SERVICELINEUSERCONFIG", data)
        .then((response) => {
          store.dispatch("showToast", {
            message: "LISTADO DE PLANTA",
            type: "success",
          });
        })
        .catch((error) => {
          console.log("error", error);
        });
    },

    // /////////// con vVERIFYADDRESS////////

    // newServices() {
    //   this.isLoadingNewServices = true
    //   this.process = true;
    //   // console.log("NEW SERVICES, YELTSIN")
    //   // console.log(this.coordenate)
    //   store.dispatch("VERIFYADDRESS", this.coordenate).then((response2) => {
    //     console.log(response2);
    //     if (response2.data.code === 200) {
    //       this.validAddress = true;
    //       if (response2.data.data.results[1].code === 200) {
    //         store.dispatch("showToast", {
    //           message: "Direccion Válida",
    //           type: "success",
    //         });
    //         var data = {
    //           endpoint: 22,
    //           type: "api",
    //           requestBody: {
    //             accountNumber: this.account.name,
    //             platformId: process.env.VUE_APP_PLATFORM,
    //             principalId: this.principal.principalId,
    //             deviceId: response2.data.data.requestResults.deviceId.id,
    //             directionId: response2.data.data.results[1].data.id,
    //             serviceplanId: response2.data.data.requestResults.serviceplanId.serviceplanId,
    //             nickname: response2.data.data.requestResults.nickname,
    //             detail: response2.data.data.requestResults.detail || null,
    //           }
    //         }

    //         // console.log("DATA", data)
    //         store.dispatch("VERIFYADDRESS", data).then((response3) => {
    //           if (response3.data.code === 200) {
    //             store.dispatch("showToast", {
    //               message: "Creada Correctamente",
    //               type: "success",
    //             });
    //             var data2 = {
    //               endpoint: 1,
    //             };
    //             this.serviceline(data2);
    //             this.$emit("close");
    //           } else {
    //             store.dispatch("showToast", {
    //               message: "Error al crear",
    //               type: "success",
    //             });
    //           }
    //         }).catch((error) => {
    //           this.validAddress = false;
    //           store.dispatch("showToast", {
    //             message: String(error.response.data.message[0]),
    //             type: "error",
    //           });
    //         });
    //         // console.log(data)
    //       } else {
    //         store.dispatch("showToast", {
    //           message: "Error al Crear Direccion",
    //           type: "success",
    //         });
    //         this.isLoadingNewServices = false
    //       }
    //     } else {
    //       this.validAddress = false;
    //       store.dispatch("showToast", {
    //         message: response2.data.message[0],
    //         type: "error",
    //       });
    //     }
    //     // let dataNewService = {
    //     //   endpoint: 1,
    //     // };
    //     // this.serviceline(dataNewService);
    //     this.isLoadingNewServices = false
    //   }).catch((error) => {
    //     this.validAddress = false;
    //     this.isLoadingNewServices = false
    //     store.dispatch("showToast", {
    //       message: String(error.response.data.message[0]),
    //       type: "error",
    //     });
    //   });
    // },

    // /////////// con vVERIFYADDRESS////////


    // ////////////////////////newservices 111111////////////
    // newServices() {
    //   this.isLoadingNewServices = true;
    //   this.process = true;
    //   console.log("1111111111111111111111");

    //   // Definir el tipo para el payload
    //   interface RequestBody {
    //     locality: string | null;
    //     addressLines: string;
    //     administrativeArea: string;
    //     latitude: number;
    //     longitude: number;
    //     [key: string]: any; // Para otros campos adicionales
    //   }

    //   // Validar los campos obligatorios en el payload
    //   const validateRequestBody = (requestBody: RequestBody) => {
    //     const requiredFields = ["locality", "addressLines", "administrativeArea", "latitude", "longitude"];
    //     const missingFields = requiredFields.filter(field => !requestBody[field]);

    //     if (missingFields.length > 0) {
    //       throw new Error(`Faltan los campos obligatorios: ${missingFields.join(", ")}`);
    //     }
    //   };

    //   // Asignar un valor por defecto si locality es null
    //   if (!this.coordenate.requestBody.locality) {
    //     this.coordenate.requestBody.locality = "Ñuble"; // Valor predeterminado
    //   }

    //   // Validar el payload antes de enviarlo
    //   try {
    //     validateRequestBody(this.coordenate.requestBody as RequestBody);
    //   } catch (error) {
    //     if (error instanceof Error) {
    //       console.error("Error de validación:", error.message);
    //       store.dispatch("showToast", {
    //         message: error.message,
    //         type: "error",
    //       });
    //     } else {
    //       console.error("Error desconocido:", error);
    //       store.dispatch("showToast", {
    //         message: "Error desconocido",
    //         type: "error",
    //       });
    //     }
    //     this.isLoadingNewServices = false;
    //     return;
    //   }

    //   // Enviar el payload al backend
    //   store.dispatch("CREATEADDRESSREFERENCEDB", this.coordenate.requestBody)
    //     .then((response2) => {
    //       console.log("test1111");
    //       console.log("Respuesta de CREATEADDRESSREFERENCEDB:", response2);

    //       // Validar si la dirección es válida
    //       if (response2.data?.isValid) {
    //         this.validAddress = true;
    //         store.dispatch("showToast", {
    //           message: "Dirección válida",
    //           type: "success",
    //         });

    //         console.log("first11111");
    //         console.log("second22222", this.coordenate.requestBody);

    //         // Crear el objeto de datos para el terminal
    //         this.terminalData = {
    //           accountNumber: this.account.name,
    //           platformId: process.env.VUE_APP_PLATFORM,
    //           principalId: this.principal.principalId,
    //           deviceId: this.coordenate.requestBody.deviceId.id,
    //           directionId: response2.data.data,
    //           serviceplanId: this.coordenate.requestBody.serviceplanId.serviceplanId,
    //           nickname: this.coordenate.requestBody.nickname,
    //           detail: "null",
    //         };

    //         console.log("Datos para crear terminal:", this.terminalData);

    //         // Enviar la solicitud para crear el terminal
    //         return store.dispatch("CREATETERMINALDB", this.terminalData);
    //       } else {
    //         throw new Error(response2.data?.message?.[0] || "Dirección no válida");
    //       }
    //     })
    //     .then((response) => {
    //       console.log("Respuesta de CREATETERMINALDB:", response);

    //       // Validar si la creación del terminal fue exitosa
    //       if (response.data?.isValid === true) {
    //         store.dispatch("showToast", {
    //           message: "Servicio creado exitosamente",
    //           type: "success",
    //         });

    //         // Enviar correo con la respuesta del servidor
    //         const terminalDetails = response.data?.details || [];
    //         this.enviarCorreo({
    //           ...this.terminalData,
    //           details: terminalDetails,
    //         });

    //         // Cerrar el popup
    //         this.closePopup();
    //       } else {
    //         throw new Error(response.data?.message?.[0] || "Error al crear servicio");
    //       }
    //     })
    //     .catch((error) => {
    //       if (error instanceof Error) {
    //         console.error("Error en el flujo:", error.message);
    //         store.dispatch("showToast", {
    //           message: error.message,
    //           type: "error",
    //         });
    //       } else {
    //         console.error("Error desconocido:", error);
    //         store.dispatch("showToast", {
    //           message: "Error desconocido",
    //           type: "error",
    //         });
    //       }
    //     })
    //     .finally(() => {
    //       this.isLoadingNewServices = false;
    //     });
    // },

    // ////////////////////////newservices 111111////////////





    // ////////////////////////newservices 222222////////////

    // async newServices() {
    //   this.isLoadingNewServices = true;
    //   this.process = true;

    //   // Definir el tipo para el payload
    //   interface RequestBody {
    //     locality: string | null;
    //     addressLines: string;
    //     administrativeArea: string;
    //     latitude: number;
    //     longitude: number;
    //     [key: string]: any; // Para otros campos adicionales
    //   }

    //   try {
    //     console.log("1111111111111111111111");

    //     // Validar los campos obligatorios en el payload
    //     const validateRequestBody = (requestBody: RequestBody) => {
    //       const requiredFields = ["locality", "addressLines", "administrativeArea", "latitude", "longitude"];
    //       const missingFields = requiredFields.filter(field => !requestBody[field]);

    //       if (missingFields.length > 0) {
    //         throw new Error(`Faltan los campos obligatorios: ${missingFields.join(", ")}`);
    //       }
    //     };

    //     // Asignar un valor por defecto si locality es null
    //     if (!this.coordenate.requestBody.locality) {
    //       this.coordenate.requestBody.locality = "Ñuble"; // Valor predeterminado
    //     }

    //     // Validar el payload antes de enviarlo
    //     validateRequestBody(this.coordenate.requestBody as RequestBody);

    //     // Llamada a CREATEADDRESSREFERENCEDB
    //     const response2 = await store.dispatch("CREATEADDRESSREFERENCEDB", this.coordenate.requestBody);
    //     console.log("Respuesta de CREATEADDRESSREFERENCEDB:", response2);

    //     if (!response2.data?.isValid) {
    //       throw new Error(response2.data?.message?.[0] || "Dirección no válida");
    //     }

    //     // Si la dirección es válida, procesar
    //     this.validAddress = true;
    //     store.dispatch("showToast", {
    //       message: "Dirección válida",
    //       type: "success",
    //     });

    //     this.terminalData = {
    //       accountNumber: this.account.name,
    //       platformId: process.env.VUE_APP_PLATFORM,
    //       principalId: this.principal.principalId,
    //       deviceId: this.coordenate.requestBody.deviceId.id,
    //       directionId: response2.data.data,
    //       serviceplanId: this.coordenate.requestBody.serviceplanId.serviceplanId,
    //       nickname: this.coordenate.requestBody.nickname,
    //       detail: "null",
    //     };

    //     console.log("Datos para crear terminal:", this.terminalData);

    //     // Llamada a CREATETERMINALDB
    //     const response = await store.dispatch("CREATETERMINALDB", this.terminalData);
    //     console.log("Respuesta de CREATETERMINALDB:", response);

    //     if (!response.data?.isValid) {
    //       throw new Error(response.data?.message?.[0] || "Error al crear servicio");
    //     }

    //     // Si se creó el terminal con éxito
    //     store.dispatch("showToast", {
    //       message: "Servicio creado exitosamente",
    //       type: "success",
    //     });

    //     // Enviar correo con la respuesta del servidor
    //     const terminalDetails = response.data?.details || [];
    //     this.enviarCorreo({
    //       ...this.terminalData,
    //       details: terminalDetails,
    //     });

    //     // Llamada para refrescar la lista de planta (actualiza la vista)
    //     this.serviceline({ endpoint: 1 });

    //     // Cerrar el popup
    //     this.closePopup();
    //   } catch (error) {
    //     if (error instanceof Error) {
    //       console.error("Error en el flujo:", error.message);
    //       store.dispatch("showToast", {
    //         message: error.message,
    //         type: "error",
    //       });
    //     } else {
    //       console.error("Error desconocido:", error);
    //       store.dispatch("showToast", {
    //         message: "Error desconocido",
    //         type: "error",
    //       });
    //     }
    //   } finally {
    //     this.isLoadingNewServices = false;
    //   }
    // },

    // ////////////////////////newservices 222222////////////


    async newServices() {
      this.isLoadingNewServices = true;
      this.process = true;

      try {
        // Validar deviceId
        if (!this.coordenate.requestBody.deviceId || !this.coordenate.requestBody.deviceId.id) {
          throw new Error("El dispositivo seleccionado no es válido.");
        }

        // Validar `locality` y otros campos requeridos
        if (!this.coordenate.requestBody.locality) {
          this.coordenate.requestBody.locality = "Ñuble";
        }

        // Llamada a CREATEADDRESSREFERENCEDB
        const response2 = await store.dispatch("CREATEADDRESSREFERENCEDB", this.coordenate.requestBody);
        if (!response2.data?.isValid) {
          throw new Error(response2.data?.message?.[0] || "Dirección no válida");
        }

        this.validAddress = true;
        store.dispatch("showToast", { message: "Dirección válida", type: "success" });

        // Crear terminalData
        this.terminalData = {
          accountNumber: this.account.name,
          platformId: process.env.VUE_APP_PLATFORM,
          principalId: this.principal.principalId,
          deviceId: this.coordenate.requestBody.deviceId.id,
          directionId: response2.data.data,
          serviceplanId: this.coordenate.requestBody.serviceplanId.serviceplanId,
          nickname: this.coordenate.requestBody.nickname,
          detail: "null",
        };

        // Llamada a CREATETERMINALDB
        const response = await store.dispatch("CREATETERMINALDB", this.terminalData);
        if (!response.data?.isValid) {
          throw new Error(response.data?.message?.[0] || "Error al crear servicio");
        }

        store.dispatch("showToast", { message: "Servicio creado exitosamente", type: "success" });

        // Cerrar popup y refrescar lista
        setTimeout(() => {
          this.closePopup();
          this.serviceline({ endpoint: 1 }); // Refresca lista de servicios
        }, 1000);
      } catch (error) {
        store.dispatch("showToast", {
          message: error instanceof Error ? error.message : "Error desconocido",
          type: "error",
        });
      } finally {
        this.isLoadingNewServices = false;
      }
    },






    verificarAddress() {
      // console.log("coordenate", this.coordenate)
      if (this.permissions.some((evt: any) => evt.id === 7)) {
        var dataValid = {
          endpoint: 18,
          type: "api",
          requestBody: {
            $address: this.newService.direction
          }
        }
        store.dispatch("VERIFYADDRESS", dataValid).then((response2) => {
          // console.log("VERIFYADDRESS", response2);
          if (response2.data.code === 200) {
            //   console.log('status 200');
            // this.validAddress = true;
            if (response2.data.data.results.length > 0) {
              //     console.log('hau resultados');
              var dataValue = response2.data.data.results[0];
              // console.log("dataValue", dataValue)
              var country = {
                long_name: null,
                short_name: null
              };
              var postalCodeRef = {
                long_name: null,
                short_name: null
              };
              var localCodeRef = {
                long_name: null,
                short_name: null
              };
              dataValue.results[0].address_components.forEach((component: any) => {
                component.types.forEach((evt: any) => {
                  if (evt === "country") {
                    country = component;
                  }
                  if (evt === "postal_code") {
                    postalCodeRef = component;
                  }
                  if (evt === "locality") {
                    localCodeRef = component;
                  }
                })
              });


              var dataValid = {
                endpoint: 19,
                type: "api",
                requestBody: {
                  nickname: null,
                  deviceId: null,
                  serviceplanId: null,
                  accountNumber: this.account.name,
                  principalId: this.principal.principalId,
                  platformId: process.env.VUE_APP_PLATFORM,
                  addressLines: response2.data.data.results[0].results[0].formatted_address,
                  locality: localCodeRef ? localCodeRef.long_name : "",
                  administrativeArea: country ? country.short_name : "", // mayuscula
                  administrativeAreaCode: country ? country.short_name : "", // mayuscula
                  region: country ? country.long_name : "",
                  regionCode: country ? country.short_name : "", // mayuscula
                  latitude: response2.data.data.results[0].results[0].geometry.location.lat,
                  longitude: response2.data.data.results[0].results[0].geometry.location.lng,
                  formattedAddress: response2.data.data.results[0].results[0].formatted_address,
                  postalCode: postalCodeRef ? postalCodeRef.long_name : "",
                }
              }

              // console.log("dataValid", dataValid)
              store.commit("SET_COORDENATE", dataValid);
              // console.log('ok');
              this.serviceplan();
              this.validAddress = true;

            } else {
              store.dispatch("showToast", {
                message: "NO ENCONTRO DIRECCIÓN",
                type: "error",
              });
            }
            store.dispatch("showToast", {
              message: "VERIFICADO",
              type: "success",
            });
          } else {
            this.validAddress = false;
            store.dispatch("showToast", {
              message: response2.data.message[0],
              type: "error",
            });
          }
          // console.log(response2);
        }).catch((error) => {
          this.validAddress = false;
          store.dispatch("showToast", {
            message: String(error.response.data.message[0]),
            type: "error",
          });
        });
      } else {
        this.process = false;
        store.dispatch("showToast", {
          message: "NO TIENES PERMISOS PARA ESTA ACCION",
          type: "error",
        });
      }
    },



    enviarCorreo(value: any) {
      var data = {
        body: `Estimados Buen día.
        Se realizó el alta del servicio ${value.nickname} asociado al KIT ${value.device.kitSerialNumber}, 
        se requiere actualizar la politica de IP a "Public IP" en plataforma Starlink.`,
        asunto: `ALTA DE SERVICIO ${value.nickname}`,
        correo_destino: "nocmnla.fija.pe@telefonica.com",
        cc: ["alejandro.chacon@telefonica.com", "orderingmn.pe@telefonica.com", "elvis.shahuano@wydsoluciones.com", "andres.tello@wydsoluciones.com"]
      };
      store
        .dispatch("SEND_MAIL", data)
        .then((response) => {
          store.dispatch("showToast", {
            message: "CORREO ENVIADO EXITOSAMENTE",
            type: "success",
          });
        })
        .catch((error) => {
          console.log("error", error);
        });

    },
  },
});
</script>

<style lang="scss">
.dropdown-content {
  position: fixed;
  display: none;
  width: 160px;
  height: 225px;
  background: #0066ff;
  border-radius: 10px;
  margin-top: 0px;
}

.dropdown-content:last-of-type {
  position: fixed;
  display: none;
  width: 160px;
  height: 225px;
  background: #0066ff;
  border-radius: 10px;
  margin-top: -225px;
  margin-left: 45px;
}

.dropdown-content:hover {
  position: fixed;
  display: none;
  width: 160px;
  height: 225px;
  background: #0066ff;
  border-radius: 10px;
}

.dropdown-content a:hover {
  background-color: #0079ff;
}

.btn-submit-address {
  height: 32px;
  width: 220px;
  border: none;
  border-radius: 9px;
  color: #ffffff;
  font-weight: bold;
  background-color: #0066ff;
}

.btn-submit-mapa {
  height: 32px;
  width: 220px;
  border: none;
  border-radius: 9px;
  color: #ffffff;
  font-weight: bold;
  background-color: #0066ff;
}

.custom-select .vs__dropdown-toggle {
  background-color: white;
}

.custom-input {
  background-color: white;
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 4px;
  width: 95%;
  cursor: pointer;
}

.header-content {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.spinner-content2 {
  display: flex;
  justify-content: center;
  align-items: center;
  // min-width: 1300px;
  // min-height: 600px;
}
</style>
